import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../components/service-hero";
import Badges from "../components/badges";
import OurServicesSection from "../components/our-services-section";
import Reviews from "../components/reviews";
import ImageSideContainer from "../components/image-side-container";
import { Button } from "react-bootstrap";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			Img5: wpMediaItem(title: { eq: "TM-2-AU" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			badgeImg: wpMediaItem(title: { eq: "Badge" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "about-us" }) {
				aboutUsFields {
					aboutBanner {
						aboutBannerHeading
						aboutBannerBackgroundImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
						}
					}
					aboutContent {
						aboutContentHeading
						aboutContentText
					}
					aboutLeftContentRightImage {
						aboutLeftContentText
						aboutSectionRightImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: TRACED_SVG
										)
									}
								}
							}
						}
					}
					aboutLeftImageRightContent {
						aboutSectionRightContent
						aboutSectionLeftImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: TRACED_SVG
										)
									}
								}
							}
						}
					}
					aboutBgImageWhiteOverlaySection {
						aboutOverlayHeading
						aboutOverlayContentText
						aboutOverlayContentButton {
							title
							target
							url
						}
						aboutOverlayContentBgImage {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: TRACED_SVG
										)
									}
								}
							}
						}
					}
					aboutMeetTeamSection {
						aboutMeetTeamHeading
						aboutMeetTeamButton {
							title
							target
							url
						}
						aboutMeetTeamMembers {
							aboutTeamMemberName
							aboutTeamMemberDesignation
							aboutTeamMemberImage {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: TRACED_SVG
											)
										}
									}
								}
							}
						}
					}
					aboutApprovedVehicleLogoSlider {
						aboutLogoSliderHeading
						aboutSliderBottomNotice
						aboutLogoSlider {
							nodes {
								... on WpVehicleLogo {
									vehicleLogoFields {
										logoUrl
										logoImage {
											node {
												altText
												localFile {
													publicURL
													childImageSharp {
														gatsbyImageData(
															formats: [AUTO, WEBP]
															quality: 100
															transformOptions: {
																cropFocus: CENTER
																fit: CONTAIN
															}
															layout: CONSTRAINED
															placeholder: BLURRED
														)
													}
												}
											}
										}
									}
								}
							}
						}
					}
					aboutReviews {
						aboutReviewsHeading
					}
					aboutServicesCardsSection {
						aboutServicesCardsHeading
						aboutServicesCards {
							nodes {
								... on WpService {
									title
									servicesPostFields {
										serviceUrl {
											target
											title
											url
										}
									}
									featuredImage {
										node {
											altText
											localFile {
												publicURL
												childImageSharp {
													gatsbyImageData(
														formats: [AUTO, WEBP]
														quality: 100
														transformOptions: {
															cropFocus: CENTER
															fit: CONTAIN
														}
														layout: CONSTRAINED
														placeholder: BLURRED
													)
												}
											}
										}
									}
								}
							}
						}
					}
				}
				seoFields {
					title
					opengraphTitle
					opengraphDescription
					metaDescription
					localBusinessSchema
					image {
						node {
							altText
							localFile {
								childImageSharp {
									original {
										height
										width
									}
								}
								publicURL
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, aboutUsFields },
	} = data;

	const {
		aboutBanner,
		aboutContent,
		aboutLeftImageRightContent,
		aboutBgImageWhiteOverlaySection,
		aboutLeftContentRightImage,
		aboutApprovedVehicleLogoSlider,
		aboutReviews,
		aboutMeetTeamSection,
		aboutServicesCardsSection,
	} = aboutUsFields;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const Img5 = data.Img5?.localFile.childImageSharp.gatsbyImageData;
	const badgeImg = data.badgeImg?.localFile.childImageSharp.gatsbyImageData;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			{aboutBanner && (
				<section>
					<ServiceHero
						title={aboutBanner.aboutBannerHeading}
						backgroundImage={
							aboutBanner.aboutBannerBackgroundImage.node?.localFile
								.childImageSharp.gatsbyImageData
						}
					/>
				</section>
			)}

			{aboutContent &&
				!checkPropertiesForNull(aboutContent, ["aboutContentHeading"]) && (
					<section className="pt-5  pt-lg-8 position-relative">
						<Container>
							<Row>
								<Col className="position-relative">
									<h2 className=" text-center pb-5">
										{aboutContent.aboutContentHeading}
									</h2>
									<SafeHtmlParser
										htmlContent={aboutContent?.aboutContentText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
				)}

			{aboutLeftImageRightContent &&
				!checkPropertiesForNull(aboutLeftImageRightContent, [
					"aboutSectionRightContent",
				]) && (
					<div className="pb-5 pb-lg-8 ">
						<ImageSideContainer
							image={
								aboutLeftImageRightContent.aboutSectionLeftImage.node?.localFile
									.childImageSharp.gatsbyImageData
							}
							imageAlt={
								aboutLeftImageRightContent.aboutSectionLeftImage.node?.altText
							}
							title=""
							order="first"
							imagePadding=" pb-5 pb-lg-0    "
							text={aboutLeftImageRightContent.aboutSectionRightContent}
						/>
					</div>
				)}

			{aboutBgImageWhiteOverlaySection &&
				!checkPropertiesForNull(aboutBgImageWhiteOverlaySection, [
					"aboutOverlayHeading",
				]) && (
					<section>
						{" "}
						<BgImage
							style={{ backgroundPosition: "top" }}
							alt={
								aboutBgImageWhiteOverlaySection.aboutOverlayContentBgImage?.node
									?.altText
							}
							image={getImage(
								aboutBgImageWhiteOverlaySection.aboutOverlayContentBgImage?.node
									?.localFile.childImageSharp.gatsbyImageData
							)}
						>
							<Container
								style={{ zIndex: 2 }}
								className="position-relative py-xl-10 py-5 "
							>
								<Row className="justify-content-end">
									<Col lg={7} className="">
										<div className="position-relative  p-5">
											<div
												style={{
													zIndex: 1,
													opacity: 0.8,
													backgroundColor: "white",
												}}
												className="position-absolute h-100 w-100 start-0 bottom-0"
											></div>
											<div className="position-relative" style={{ zIndex: 2 }}>
												<h2 className=" pb-4">
													{aboutBgImageWhiteOverlaySection.aboutOverlayHeading}
												</h2>

												<SafeHtmlParser
													htmlContent={
														aboutBgImageWhiteOverlaySection?.aboutOverlayContentText
													}
												/>
												{aboutBgImageWhiteOverlaySection.aboutOverlayContentButton &&
													aboutBgImageWhiteOverlaySection
														.aboutOverlayContentButton.url && (
														<Row>
															<Col className="text-center text-md-start">
																<Button
																	style={{ borderRadius: "0px" }}
																	variant="secondary"
																	as={Link}
																	to={
																		aboutBgImageWhiteOverlaySection
																			.aboutOverlayContentButton.url
																	}
																	className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
																	target={
																		aboutBgImageWhiteOverlaySection
																			.aboutOverlayContentButton.target ??
																		"_self"
																	}
																>
																	{
																		aboutBgImageWhiteOverlaySection
																			.aboutOverlayContentButton.title
																	}
																</Button>
															</Col>
														</Row>
													)}
											</div>
										</div>
									</Col>
								</Row>
							</Container>
						</BgImage>
					</section>
				)}

			{aboutLeftContentRightImage &&
				!checkPropertiesForNull(aboutLeftContentRightImage, [
					"aboutLeftContentText",
				]) && (
					<div className="py-5 py-lg-8">
						<ImageSideContainer
							image={
								aboutLeftContentRightImage.aboutSectionRightImage.node
									?.localFile.childImageSharp.gatsbyImageData
							}
							imageAlt={
								aboutLeftContentRightImage.aboutSectionRightImage.node?.altText
							}
							title=""
							order="last"
							imagePadding=" pb-5 pb-lg-0    "
							text={aboutLeftContentRightImage.aboutLeftContentText}
						/>
					</div>
				)}
			{aboutMeetTeamSection &&
				!checkPropertiesForNull(aboutMeetTeamSection, [
					"aboutMeetTeamHeading",
				]) && (
					<section className="gradient py-5 py-lg-8">
						<Container>
							<Row className="pb-xl-5 gx-md-5 justify-content-xl-between">
								<Col className="mb-5 mb-xl-0 me-4" lg={7}>
									<h2 className="text-white mb-4">
										{aboutMeetTeamSection.aboutMeetTeamHeading}
									</h2>
									{aboutMeetTeamSection.aboutMeetTeamButton &&
										aboutMeetTeamSection.aboutMeetTeamButton.url && (
											<Button
												style={{ borderRadius: "0px" }}
												variant="secondary"
												as={Link}
												to={aboutMeetTeamSection.aboutMeetTeamButton.url}
												className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
												target={
													aboutMeetTeamSection.aboutMeetTeamButton.target ??
													"_self"
												}
											>
												{aboutMeetTeamSection.aboutMeetTeamButton.title}
											</Button>
										)}
								</Col>
								<Col
									className="mb-5 mb-xl-0 d-none d-lg-block"
									md={6}
									lg={true}
								>
									<div style={{ opacity: "0" }} className="position-relative ">
										<GatsbyImage image={Img5} alt="" />
									</div>
								</Col>
								{aboutMeetTeamSection.aboutMeetTeamMembers
									.slice(0, 1)
									.map((item) => (
										<Col
											className="mb-5 d-none d-lg-block mb-xl-0"
											md={6}
											lg={true}
										>
											<div className="position-relative">
												<div
													style={{ zIndex: 3 }}
													className="position-absolute start-0 top-0 d-flex align-items-end p-3 justify-content-start team-link h-100 w-100"
												>
													<div
														style={{ opacity: 0.5, backgroundColor: "black" }}
														className="position-absolute w-100 h-100 top-0 start-0 "
													></div>
													<div className="d-block">
														<p
															style={{ zIndex: 4 }}
															className="inter-bold fs-4 d-lg-none d-xl-block position-relative pb-0 mb-0 text-white"
														>
															{item.aboutTeamMemberName}
														</p>
														<p
															style={{ zIndex: 4 }}
															className="inter-bold  d-none d-lg-block d-xl-none position-relative pb-0 mb-0 text-white"
														>
															{item.aboutTeamMemberName}
														</p>
														<p
															style={{ zIndex: 4 }}
															className="inter-bold fs-6 d-lg-none d-xl-block position-relative pb-0 mb-0 text-white"
														>
															{item.aboutTeamMemberDesignation}
														</p>
														<p
															style={{ zIndex: 4, fontSize: "60%" }}
															className="inter-bold  d-none d-lg-block d-xl-none position-relative pb-0 mb-0 text-white"
														>
															{item.aboutTeamMemberDesignation}
														</p>
													</div>
												</div>
												<GatsbyImage
													image={
														item.aboutTeamMemberImage?.node?.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={item.aboutTeamMemberImage?.node?.altText}
												/>
											</div>
										</Col>
									))}
							</Row>
							{aboutMeetTeamSection.aboutMeetTeamMembers &&
								aboutMeetTeamSection.aboutMeetTeamMembers.length > 0 && (
									<Row className="justify-content-xl-between gx-md-5">
										{aboutMeetTeamSection.aboutMeetTeamMembers.map(
											(teamItem, i) => (
												<Col
													className={`mb-5 mb-xl-0 ${
														i === 0 ? "d-lg-none" : ""
													}`}
													md={6}
													lg={true}
												>
													<div className="position-relative">
														{i === 1 && (
															<div
																style={{ zIndex: 4 }}
																className="position-absolute d-none d-md-block top-0 start-0  translate-middle "
															>
																<GatsbyImage
																	style={{ maxWidth: "90px" }}
																	image={badgeImg}
																	alt={data.badgeImg.altText}
																/>
															</div>
														)}

														<div
															style={{ zIndex: 4 }}
															className="position-absolute d-md-none top-0 start-50 translate-middle "
														>
															<GatsbyImage
																style={{ maxWidth: "90px" }}
																image={
																	teamItem.aboutTeamMemberImage?.node?.localFile
																		.childImageSharp.gatsbyImageData
																}
																alt={
																	teamItem.aboutTeamMemberImage?.node?.altText
																}
															/>
														</div>
														<div
															style={{ zIndex: 3 }}
															className="position-absolute start-0 top-0  d-flex align-items-end p-3 justify-content-start team-link h-100 w-100"
														>
															<div
																style={{
																	opacity: 0.5,
																	backgroundColor: "black",
																}}
																className="position-absolute w-100 h-100 top-0 start-0 "
															></div>
															<div className="d-block">
																<p
																	style={{ zIndex: 4 }}
																	className="inter-bold fs-4 d-lg-none d-xl-block position-relative pb-0 mb-0 text-white"
																>
																	{teamItem.aboutTeamMemberName}
																</p>
																<p
																	style={{ zIndex: 4 }}
																	className="inter-bold  d-none d-lg-block d-xl-none position-relative pb-0 mb-0 text-white"
																>
																	{teamItem.aboutTeamMemberName}
																</p>
																<p
																	style={{ zIndex: 4 }}
																	className="inter-bold fs-6 d-lg-none d-xl-block position-relative pb-0 mb-0 text-white"
																>
																	{teamItem.aboutTeamMemberDesignation}
																</p>
																<p
																	style={{ zIndex: 4, fontSize: "60%" }}
																	className="inter-bold  d-none d-lg-block d-xl-none position-relative pb-0 mb-0 text-white"
																>
																	{teamItem.aboutTeamMemberDesignation}
																</p>
															</div>
														</div>
														<GatsbyImage
															image={
																teamItem.aboutTeamMemberImage?.node?.localFile
																	.childImageSharp.gatsbyImageData
															}
															alt={teamItem.aboutTeamMemberImage?.node?.altText}
														/>
													</div>
												</Col>
											)
										)}
									</Row>
								)}
						</Container>
					</section>
				)}

			{aboutApprovedVehicleLogoSlider &&
				!checkPropertiesForNull(aboutApprovedVehicleLogoSlider, [
					"aboutLogoSliderHeading",
				]) && (
					<Badges
						title={aboutApprovedVehicleLogoSlider.aboutLogoSliderHeading}
						text={aboutApprovedVehicleLogoSlider.aboutSliderBottomNotice}
						logos={aboutApprovedVehicleLogoSlider.aboutLogoSlider?.nodes}
					/>
				)}

			<section>
				<Reviews title={aboutReviews?.aboutReviewsHeading} />
			</section>

			{/* {aboutServicesCardsSection &&
        !checkPropertiesForNull(aboutServicesCardsSection, [
          "aboutServicesCardsHeading",
        ]) && (
          <OurServicesSection
            title={aboutServicesCardsSection.aboutServicesCardsHeading}
            services={aboutServicesCardsSection.aboutServicesCards?.nodes}
          />
        )} */}
		</Layout>
	);
};

export default AboutUsPage;
